import React from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import { Container } from "../components/container";
import { Intro, TextSection } from "../components/Section";
import { Footer } from "../components/Footer";

import uDev from "../assets/UDEV.svg";
import { Hero } from "../components/Hero";
import { Toggle } from "../components/Colorchange";
import { MainContent, openChat } from "../components/helpers";

class UDev extends React.Component
{
    render()
    {
        const siteTitle = get(this, "props.data.site.siteMetadata.title");
        const siteDescription = get(
            this,
            "props.data.site.siteMetadata.description"
        );

        return (
            <Layout location={ this.props.location }>
                <Helmet
                    htmlAttributes={ { lang: "en" } }
                    meta={ [{ name: "description", content: siteDescription }] }
                    title={ siteTitle }
                />
                <Container>
                    <Hero image={ uDev } imageSize={ "300px" }>
                        <h1>U DEV</h1>
                        <p>The User Experience Team</p>
                        <br/>
                        <Toggle onClick={ openChat } className="base btn-rainbow">Get in touch with us</Toggle>
                    </Hero>
                    <MainContent>
                        <Intro>
                            <h1>User Experience</h1>
                            <p>Your users experience is the most important element of your site and we make this the
                                primary
                                focus as we work with you.</p>
                        </Intro>
                        <TextSection>
                            <h2>Design</h2>
                            <p>The design of your site is often your customer's first impression of your company and
                                brand.
                                Our experience will guide the development of perfect design for your brand and
                                project.</p>
                        </TextSection>
                        <TextSection flow={ "right" }>
                            <h2>Accessibility</h2>
                            <p>Accessibility is more important than ever. We make sure your app can be experienced by
                                everyone, no matter who they are.</p>
                        </TextSection>
                    </MainContent>
                </Container>
                <Footer/>
            </Layout>
        );
    }
}

export default UDev;

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
                description
            }
        }
    }
`;
